/* foreninger local-new.less */

@import "../../../../../../../lib/x1/css/x-0.less";



// general
a {
  text-decoration-thickness: 1px;
  text-underline-offset: .05em;
}

abbr {
  text-decoration: none;
}



// header
#header {
  // logo. supports img or text (not both)
  .logo {
    background: none; // uses content img
    width: auto;
    overflow: hidden;

    // in case of text
    text-decoration: none;
    line-height: 1.3;
    @media (@screen-below-large) {
      font-size: 1.4rem;
    }
  }
  // uio-logo hidden except for front page directory
  .uio-logo {
    display: block;
  }
  // front page directory logo (added unique id)
  .logo#frontpage-directory {
    @media (@screen-large) {
      font-size: 3rem;

      // show uio-logo on this page only
      ~ .uio-logo {
        display: block;
      }
    }
    @media (@screen-below-large) {
      font-size: inherit;
    }
  }
}



// footer
#footer-wrapper {
  padding-bottom: 100px;
}



// left main and menu not in use
#left-main,
#left-menu-same-level-folders {
  display: none;
}



// directory page intro (added div)
.directory-intro {
  max-width: var(--width-soft-max);
  margin-bottom: calc(2*var(--space));
}



// subfolder menu (mainly used on directory page)
.vrtx-subfolder-menu {
  column-width: 300px;
  gap: 50px;
  @media (@screen-medium) {
    padding: 30px 30px 40px;
  }
  ul {
    li {
      padding: 10px 0;
    }
  }
}



// subscribe component at bottom of event list page
.vrtx-subscribe-component {
  display: none;
}



// search result page. hide advanced search
#vrtx-searchview {
  #vrtx-show-advanced-search,
  #vrtx-advanced-search-wrapper {
    display: none;
  }
}
